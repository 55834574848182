<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
#app {
  width: 100%;
  min-width: 1400px;
  
  height: 100%;
  background: #ffffff;
}
@media screen and (max-width: 500px) {
     #app{
       min-height: 3000px;
     }
}
</style>