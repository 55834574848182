import Vue from 'vue'
import VueRouter from 'vue-router'
import https from '@/utils/https'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/route',
    name: 'route',
    component: () => import('../views/router.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/Home.vue'),
    children: [
      {
        path: '/home/goin',
        name: 'goin',
        component: () => import('../views/GoIn.vue'),
        children: [
          {
            path: '/home/goin/market',
            name: 'market',
            component: () => import('../views/goin/market.vue')
          },
          {
            path: '/home/goin/oneLevel',
            name: 'oneLevel',
            component: () => import('../views/goin/oneLevel.vue')
          },
          {
            path: '/home/goin/secLevel',
            name: 'secLevel',
            component: () => import('../views/goin/secLevel.vue')
          },
          {
            path: '/home/goin/otherLevel',
            name: 'otherLevel',
            component: () => import('../views/goin/otherLevel.vue')
          },
          {
            path: '/home/goin/EIT',
            name: 'EIT',
            component: () => import('../views/goin/EIT.vue')
          },
          {
            path: '/home/goin/observe',
            name: 'observe',
            component: () => import('../views/goin/observe.vue')
          },
          {
            path: '/home/goin/goinDetail',
            name: 'goinDetail',
            component: () => import('../views/goin/goinDetail.vue')
          },
        ]
      },
      {
        path: '/home/congestion',
        name: 'congestion',
        component: () => import('../views/News.vue'),
        children: [
          {
            path: '/home/congestion/indexLevel',
            name: 'indexLevel',
            component: () => import('../views/congestion/indexLevel.vue')
          },
          {
            path: '/home/congestion/oneLevel',
            name: 'oneLevel',
            component: () => import('../views/congestion/oneLevel.vue')
          },
          {
            path: '/home/congestion/secLevel',
            name: 'secLevel',
            component: () => import('../views/congestion/secLevel.vue')
          },
          {
            path: '/home/congestion/otherLevel',
            name: 'otherLevel',
            component: () => import('../views/congestion/otherLevel.vue')
          },
          {
            path: '/home/congestion/EIT',
            name: 'EIT',
            component: () => import('../views/congestion/EIT.vue')
          },
          {
            path: '/home/congestion/congestinDetail',
            name: 'congestinDetail',
            component: () => import('../views/congestion/congestinDetail.vue')
          },
        ]
      },
      {
        path: '/home/moneyflow',
        name: 'moneyflow',
        component: () => import('../views/Money.vue'),
        children: [
          {
            path: '/home/moneyflow/publicfund',
            name: 'publicfund',
            component: () => import('../views/moneyflow/publicfund.vue')
          },
          {
            path: '/home/moneyflow/privatefund',
            name: 'privatefund',
            component: () => import('../views/moneyflow/privatefund.vue')
          },
          {
            path: '/home/moneyflow/lgt',
            name: 'lgt',
            component: () => import('../views/moneyflow/lgt.vue')
          },
          {
            path: '/home/moneyflow/othermoney',
            name: 'othermoney',
            component: () => import('../views/moneyflow/othermoney.vue')
          },
        ]
      },

      {
      path: '/investigate/summary',
      name: 'summary',
      component: () => import('../views/investigate.vue'),
      children: [
        {
          path: '/home/investigate/summary',
          name: 'summary',
          component: () => import('../views/investigate/summary.vue')
        },
        {
          path: '/home/investigate/alltabledetail',
          name: 'alltabledetail',
          component: () => import('../views/investigate/alltabledetail.vue')
        },        {
          path: '/home/investigate/industabledetail',
          name: 'industabledetail',
          component: () => import('../views/investigate/industabledetail.vue')
        }]
      },

      {
        path: '/home/case',
        name: 'case',
        component: () => import('../views/Case.vue')
      },
      {
        path: '/home/dateTable',
        name: 'dateTable',
        component: () => import('../views/dateTable.vue')
      },
    ]
  },
]

const router = new VueRouter({
  routes
})
let startTime = Date.now();
let currentTime;

router.beforeEach((to, from, next) => {
  if (to.matched.some((res) => res.meta.requireAuth)) {
    if (sessionStorage.getItem('Ticket')) {
      https
        .get('Admin/User/GetUserAll')
        .then((response) => {
          if (response.type === 'success') {
            next()
          } else {
            sessionStorage.removeItem('Ticket')
            next({ path: '/login' })
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    } else {
      next({
        path: '/login',
        query: {
          redirect: to.fullPath
        }
      })
    }
  } else {
    next()
  }
})

export default router
