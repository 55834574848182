import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import VueLazyload from 'vue-lazyload'
import VueVideoPlayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
import '@/style/style.css'
import 'animate.css' // 直接引入
import * as echarts from 'echarts';
import './utils/rem'
Vue.prototype.$echarts = echarts
Vue.use(ElementUI)
Vue.use(VueAwesomeSwiper)
Vue.use(VueLazyload, {
  preLoad: 1,
  error: require('./assets/img/error.png'),
  loading: require('./assets/img/loading.gif'),
  attempt: 1
})
Vue.use(VueVideoPlayer)

Vue.config.productionTip = false

Vue.prototype.imgserver = 'http://106.15.63.183:8000'

new Vue({
  router,
  render: (h) => h(App)
}).$mount('#app')